<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb class="d-print-none" :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[content] -->
    <div>
      <!-- BOC:[header] -->
      <v-row class="mb-3 d-print-none">
        <v-col class="col-12 col-sm-6"><h1>{{$t("view.PageBackendClassroomExpress.manage_pass_title")}}</h1></v-col>
      </v-row>
      <!-- EOC -->
      <div>
      <PagePass :data="data" />
      </div>
    </div>
    <!-- EOC -->
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import PagePass from "../../components/Moderator/Express/PagePass.vue";
export default {
  components: { PagePass },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("model.name.dashboard"),
      to: { name: "Page" + this.role + "Dashboard" },
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.$t("model.name.classrooms"),
      to: { name: "PageModeratorClassroomBrowse" },
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: `${this.$t("model.name.classroom")} ${this.$route.params.parentId}`,
      to: { name: "PageModeratorClassroomRead",params:{id:this.$route.params.parentId,parent:'classroom'} },
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.$t("view.PageBackendClassroomExpress.manage_pass_title"),
      to: { name: "PageModeratorClassroomStudentExpressPassManage" },
      exact: true,
    });
    //EOC
  },
  mounted() {
   
  },
  methods: {
   
  },
};
</script>