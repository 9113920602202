<template>
   <canvas class="qr-code" :id="code+schoolCode"></canvas>
</template>

<script>
import QRious from "qrious";
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      "code",
      "schoolCode"
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
        new QRious({
        element: document.getElementById(this.code+this.schoolCode),
        size: 150,
        value: `${process.env.VUE_APP_URL_LITE}${this.$_getLocale()}/express/home?s=${this.schoolCode}&e=${this.code}`,
      });
    },
    methods: {
      //
    }
  };
</script>