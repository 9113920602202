<template>
  <div>
    <div class="d-print-none">
      <v-row class="pa-3 pb-6">
        <v-spacer></v-spacer>
        <div class="pl-3">
          <v-btn
            :to="{
              name: 'PageModeratorClassroomExpressGenerate',
              params: { parentId: $route.params.parentId, parent: 'classroom' },
            }"
            color="primary"
            elevation="2"
            >+ {{ $t("action.generate_pass") }}</v-btn
          >
        </div>
        <div class="pl-3">
          <v-btn @click="print" color="primary" elevation="2"
            >{{ $t("action.print") }} 1</v-btn
          >
        </div>
        <div class="pl-3">
          <v-btn @click="print2" color="primary" elevation="2"
            >{{ $t("action.print") }} 2</v-btn
          >
        </div>
        <div class="pl-3">
          <v-btn text @click="$router.go(-1)">
            {{ $t("action.cancel") }}
          </v-btn>
        </div>
      </v-row>
      <!-- BOC:[error] -->
      <AError :api="api"></AError>
      <!-- EOC -->
      <!-- BOC:[loader] -->
      <ALoader :isLoading="api.isLoading"></ALoader>
      <!-- EOC -->
      <v-card class="mx-auto pt-9" width="100%">
        <v-row align="center" v-if="!api.isLoading" justify="center">
          <div class="text-center">
            <v-btn
              @click="previous()"
              :disabled="currentPage == 1"
              color="primary"
              small
              elevation="2"
            >
              <v-icon dark> mdi-arrow-left-bold </v-icon>
            </v-btn>
          </div>
          <div class="px-2">
            {{ $t("string.page") }} {{ currentPage }}/{{ totalPage }}
          </div>
          <div class="text-center">
            <v-btn
              @click="next()"
              :disabled="totalPage == 1 || currentPage == totalPage"
              color="primary"
              small
              elevation="2"
            >
              <v-icon dark> mdi-arrow-right-bold </v-icon>
            </v-btn>
          </div>
        </v-row>
        <v-card-text>
          <div class="page">
            <div class="subpage">
              <div v-if="!api.isLoading && data.length == 0">
                <tr>
                  <td style="outline: thin solid">
                    <div class="text-center qr-card">
                      <div class="my-0 text-caption">No items</div>
                    </div>
                  </td>
                </tr>
              </div>
              <div class="d-flex flex-wrap">
                <div class="" v-for="item in data.slice(a, b)" :key="item.id">
                  <tr>
                    <td style="outline: thin solid">
                      <div class="text-center qr-card pt-15">
                        <div class="text-caption">
                          {{ item.School.name }}
                        </div>
                        <div class="text-caption">
                          {{ item.Classroom.name }}
                        </div>
                        <div>
                          {{ item.Student.name }}
                        </div>
                        <Pass
                          :code="item.code"
                          :schoolCode="item.School.code"
                        />
                        <div class="my-0 subtitle-2 font-weight-bold">
                          {{ $t("model.prop.studentCode") }} {{ item.code }}
                        </div>
                        <div class="my-0 text-caption">
                          {{ $t("model.prop.schoolCode") }}
                          {{ item.School.code }}
                        </div>
                      </div>
                    </td>
                  </tr>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <!-- print page 1 start -->
    <div v-if="!isPrint2">
      <div id="printPage" v-for="index in totalPage" :key="index">
        <div class="print-page d-none d-print-block">
          <div id="printMe" class="print-subpage">
            <div class="d-flex flex-wrap">
              <div
                class=""
                v-for="item in data.slice((index - 1) * 12, index * 12)"
                :key="item.id"
              >
                <tr>
                  <td style="outline: thin solid">
                    <div class="text-center print-qr-card pt-15">
                      <div class="text-caption">
                        {{ item.School.name }}
                      </div>
                      <div class="text-caption">
                        {{ item.Classroom.name }}
                      </div>
                      <div>
                        {{ item.Student.name }}
                      </div>
                      <PrintPass
                        :code="item.code"
                        :schoolCode="item.School.code"
                      />
                      <div class="my-0 subtitle-2 font-weight-bold">
                        {{ $t("model.prop.studentCode") }} {{ item.code }}
                      </div>
                      <div class="my-0 text-caption">
                        {{ $t("model.prop.schoolCode") }} {{ item.School.code }}
                      </div>
                    </div>
                  </td>
                </tr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- print page 1 end -->
    <!-- print page 2 start -->
    <div v-if="isPrint2">
      <div v-for="index in print2TotalPage" :key="index">
        <div class="print-page d-none d-print-block">
          <div id="printMe" class="print-subpage">
            <div class="d-flex flex-wrap">
              <div
                class=""
                style="outline: thin dotted"
                v-for="item in data.slice((index - 1) * 6, index * 6)"
                :key="item.id"
              >
                <tr>
                  <td>
                    <div :class="item.School && item.School.logoUrl ? 'print2-qr-card':'print2-qr-card-no-logo'" class="text-center pt-15">
                      <div class="text-caption">
                        {{ item.Classroom.name }}
                      </div>
                      <div>
                        {{ item.Student.name }}
                      </div>
                      <PrintPass
                        :code="item.code"
                        :schoolCode="item.School.code + index"
                      />
                      <div class="my-0 subtitle-2 font-weight-bold">
                        {{ $t("model.prop.studentCode") }}
                      </div>
                      <div class="my-0 text-h6 font-weight-bold">
                       {{ item.code }}
                      </div>
                    </div>
                  </td>

                  <td style="border-left: thin solid">
                    <div :class="item.School && item.School.logoUrl ? 'print2-qr-card':'print2-qr-card-no-logo'" class="text-center mb-4 ">
                      <div>
                        <SchoolLogo :school="item.School" :size="120" />
                      </div>
                       <div class="text-caption">
                        {{ item.School.name }}
                      </div>
                      <div class="my-0 text-caption">
                        {{ $t("model.prop.schoolCode") }} {{ item.School.code }}
                      </div>
                      <div class="pt-4">
                        <v-avatar
                          class="profile"
                          tile
                          size="120"
                        >
                          <img contain :src="require('@/assets/Icon.png')" />
                        </v-avatar>
                      </div>
                      <div>hamochi.my</div>
                    </div>
                  </td>
                </tr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- print page 2 end -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import Pass from "./Pass/Pass.vue";
import PrintPass from "./Pass/PrintPass.vue";
import SchoolLogo from "./SchoolLogo.vue";
export default {
  components: {
    Pass,
    PrintPass,
    SchoolLogo,
  },
  computed: mapState({
    //
  }),
  props: [],
  data: () => ({
    output: null,
    totalPage: 0,
    print2TotalPage: 0,
    currentPage: 1,
    isPrint2: false,
    a: 0,
    b: 12,
    //EOC
    data: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
    //BOC:[url]
    this.api.url = `${this.$api.servers.moderator}/v1/classroom/${this.$route.params.parentId}/express`;
    //EOC
    //BOC:[api]
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.totalPage = Math.ceil(this.data.length / 12);
      this.print2TotalPage = Math.ceil(this.data.length / 6);
      if (this.data.length == 0) this.totalPage = 1;
    };
    //EOC
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    print() {
      this.isPrint2 = false;
      setTimeout(() => {
       window.print();
      }, 100);
    },
    print2() {
      this.isPrint2 = true;
       setTimeout(() => {
       window.print();
      }, 100);
    },
    next() {
      this.currentPage = this.currentPage + 1;
      this.a = this.a + 12;
      this.b = this.b + 12;
    },
    previous() {
      this.currentPage = this.currentPage - 1;
      this.a = this.a - 12;
      this.b = this.b - 12;
    },
  },
};
</script>
<style>
.qr-card {
  width: 52.391mm;
  height: 99mm;
  /* width: 52.391mm;
  height: 74.2mm; */
}
.page {
  min-height: 297mm;
  width: 210mm;
  margin: 1cm auto;
  background: white;
}
.subpage {
  padding: 0px !important;
  margin: 0px !important;
  height: 297mm;
  width: 210mm;
}

.print-qr-card {
  width: 52.391mm;
  height: 99mm;
}

.print2-qr-card {
  width: 52.127mm;
  height: 92.3mm;
}
.print2-qr-card-no-logo {
  width: 52.127mm;
  height: 79.55mm;
}
.print-page {
  min-height: 297mm;
  width: 220mm;
  padding: 0px !important;
  margin-bottom: 0 px !important;
  margin: 0px !important;
}
.print-subpage {
  padding: 0px !important;
  border: 0px !important;
  height: 297mm;
  width: 210mm;
}
@page {
  size: A4;
  margin: 0px !important;
  padding: 0px !important;
}

@media print {
  body * {
    visibility: hidden;
    margin: 0px !important;
    padding: 0px !important;
  }

  .print-page,
  .print-page * {
    visibility: visible;
  }
  .print-subpage {
    padding: 0px !important;
    margin: 0px !important;
    height: 297mm !important;
    width: 210mm !important;
  }
  .print-page {
    margin: 0px !important;
    padding: 0px !important;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }

  .v-toolbar {
    display: none !important;
  }
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>